@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($values) {
	-webkit-box-shadow: $values;
	-moz-box-shadow: $values;
	box-shadow: $values;
}

@mixin transitions($args...){
	-webkit-transition:$args;
	-moz-transition:$args;
	-ms-transition:$args;
	-o-transition:$args;
	transition:$args;
}

@mixin transform($value) {
	-webkit-transform: $value;
	-moz-transform: $value;
	-ms-transform: $value;
	-o-transform: $value;
	transform: $value;
}

@mixin rolloverLinkEffect(){		
	// @include transition-property(opacity);
	// @include transition-duration(0.15s);
	// @include transition-timing-function(ease-in);
	transition: opacity 0.15s ease-in;
	@media (hover: hover) {
		&:hover {
			opacity: 0.7;
		}
	}
}

@mixin translater($num1, $num2, $num3) {
	-webkit-transform: translate3d($num1, $num2, $num3);
	-moz-transform: translate3d($num1, $num2, $num3);
	-ms-transform: translate3d($num1, $num2, $num3);
	-o-transform: translate3d($num1, $num2, $num3);
	transform: translate3d($num1, $num2, $num3);
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie);
}

@mixin basic-link-style($color) {
	a {
		color: $color;
		font-weight: $weight-normal;
		border-bottom: 1px solid convert-to-rgba($color);
		@include transitions(0.4s, ease-in);

		&:hover {
			opacity: 0.7;
		}
	}
}

@function convert-to-rgba($color, $opacity: 0.5) {
	@return rgba($color, $opacity);
}

@mixin inlineFlex{
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@mixin flex{
    display: -webkit-box; 
    display: -moz-box; 
    display: -ms-flexbox; 
    display: -webkit-flex; 
    display: flex;
}

@mixin ZeroPaddAndMAr{
    padding:0;
    margin:0;
}

@mixin ZeroPadding{
    padding:0;
}

@mixin ZeroMargin{
    margin:0;
}

@mixin alignYCenter{
    position: relative;
    top: 50%;
     -webkit-transform: translateY(-50%); 
     -ms-transform: translateY(-50%); 
     -moz-transform: translateY(-50%); 
}

@mixin coverBackground{
    -webkit-background-size:cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat:no-repeat; 
}