@import "../settings/variables";
@import '../utilities/mixins';

body{
    color: $primary-color;
}

.container{
    max-width: 1300px;
    margin: auto;
    padding: 0 30px;
    position: relative;
    
    @media screen and (min-width: $screen-lg) {
        min-height: calc(100vh - 75px);
    }
}

.wrapper{

    @media screen and (min-width: $screen-lg) {
        width: 75%;
    }
    
}


#About{
    @media screen and (max-width: $screen-lg-max) {
        padding-bottom: 70px;
    }
}

.links{
    color: $primary-color;
    text-decoration: none;
}

.link_copy{
    position: relative;
    display: inline-block;
    @extend p;
    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 5px;
        width: 30px;
        left: 0%;
        border-bottom: 2px dotted #e34754;;
    }
}