@import "../../styles/settings/variables/";
@import '../../styles/utilities/mixins';

.Login{
    
    &__login-details{
       @include flex;
       flex-direction: column;
       margin-bottom: 23px;

       label{
           margin-bottom: 28px;
        }

       p{
           margin-bottom: 8px;
           color: #373A3C;
           font-weight: bold;
        }

        input{
            width: 100%;
        }
    }

    &__button-container, &__password-reset{
        text-align: center;
    }

    &__button-container{
        margin-bottom: 33px;
        button{
            background-color: $blue;
            color: $white;
            padding: 12px 39px;
            border: none;
            border-radius: 5px;

        }
        
    }

    &__password-reset{
        a{
            color: $blue;
        }

        label{
            p{
                color: $greyOP;
                font-weight: bold;
            }
        }
        

        &__error-message{
            color: #ff0000 !important;
        }
    }
}