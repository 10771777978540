@import "../../styles/settings/variables/";
@import '../../styles/utilities/mixins';
@import '../../styles/settings/typography';
.ContentMenu{
    position: fixed;
    bottom: 0px;
    text-align: center;
    width: 100%;
    padding: 10px;
    z-index: 3;
    background-color: rgba($color: #d8d8d8, $alpha: .8);
    &__button{
        margin: 0px 10px;
        background-color: $blue;
        color: $white;
        padding: 12px 39px;
        border: none;
        border-radius: 5px;
        font-family: $primary-font-family;
        font-size: $font-size-p;
    }
}