@import "../settings/variables";
@import '../utilities/mixins';

// html {
//     // This defines what 1rem is
//     font-size: 62.5%; //1rem = 10px; 10px/16px = 62.5%
//     font-family: Arial, Helvetica, sans-serif;
// }

html{
    font-family: $primary-font-family
}


h1,
.h1 {
    font-family: $primary-font-family;
    font-size: $font-size-h1;
    text-transform: uppercase;
    font-weight: $weight-bold;
}

h2,
.h2 {
    font-family: $primary-font-family;
    font-size: $font-size-h2;
    font-weight: $weight-bold;
    line-height: 1.25;
}


// Paragraphs
p {
    font-family: $primary-font-family;
    margin: 10px 0px;
    font-size: $font-size-p;
    line-height: 1.25;    

    b {
        font-weight: $weight-bold;
    }
}

label{
    font-size: $font-size-p;
}

b {
    font-weight: $weight-bold;
}

em, i{
    font-style: italic;
}

ul, ol{
    padding-left: 20px;
}

li{
    font-family: $primary-font-family;
    margin: 10px 0px;
    font-size: $font-size-p;
    line-height: 1.25;    
}