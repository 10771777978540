@import "../../styles/settings/variables/";
@import '../../styles/utilities/mixins';
@import '../../styles/settings/typography';

.Vacancy{
    display: block;
    border-bottom: 1px solid $ltGrey;
    padding: 19px 0px;
    text-align: left;
    
    &:first-child{
        border-top: 1px solid $ltGrey;
    }

    &:last-child{
        margin-bottom: 100px;
    }

    &__titleItem{
        display: inline-block;

        &__visible{
            label{
                display: none;
            }
        }
    }
    &__collapse_container{
        overflow-y: auto;
        @include flex;
        flex-wrap: wrap;
        justify-content: flex-start !important;
        margin-top: 50px;
        
        &__input_item{
            @include flex;
            flex-direction: column;
            margin-bottom: 41px;

            label{
                @extend p;
                margin: 0 0 8px;
                color: $dkGrey;
                text-transform: capitalize;
                
                
            }

            &:first-child{
                width: $col-6;
            }

            &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(8), &:nth-child(9){
                width: $col-3;
            }

            &:nth-child(2), &:nth-child(5), &:nth-child(9){
                padding-left: 78px;
            }

            &:nth-child(3), &:nth-child(6) , &:nth-child(7){
                padding-right: 39px;
            }

            &:nth-child(4), &:nth-child(8){
                padding-left: 39px;
            }

            &:nth-child(6), &:nth-child(7){
                width: $col-1-5;

                label{
                    width: calc(100% + 39px);
                }
            }

            &:nth-child(10), &:nth-child(11), &:last-child{
                width: 100%;
            }


            &:nth-child(3), &:nth-child(4){
                input{
                    width: 100%;
                }
                
            }
        }
    }


    &__content_container, &__update, &__content_title{
        @include flex;
        justify-content: flex-start;
    }

    &__content_container{
        justify-content: space-between;
        padding: 0 10px;
    }

    &__update > *{
        margin: 0px 0px 0px 24px;

        button, a{
            background-color: transparent;
            border: none;
            box-shadow: none;
            padding: 0;
            line-height: 0;

            &:hover{
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding: 0;

            }
        }
    }

    &__update{
        align-content: center;
    }

    &__delete{
        i{
            color: $ltGrey;
            font-size: 18px;
            &:hover{
                color: $dkGrey;

            }
        }
    }

    &__view{
        i{
            color: $dkGrey;
            font-size: 20px;
        }
    }

    &__vacancy_info{

        &__number{
            color: $greyOP;
            margin-right: 83px;
        }

        &__job_title{

        }
    }
    

}