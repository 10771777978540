@import "./styles/settings/variables/";
@import './styles/utilities/mixins';

body{
    background-color: $ltGrey;
}

.Loading{
    text-align: center;
}
.Editor{
    &__container{
        background-color: #ffffff;
        margin: 0px auto 100px;
        max-width: 619px;
        padding: 65px 88px 46px;
        border-radius: 17px;
        &--disabled{
            pointer-events: none;
            opacity: 0.6;
        }

        h2{
            margin-bottom: 28px;
        }

        &__vacancies{
            max-width: 75%;
            text-align: center;
            

            &__new_vacancy{
                margin-top: 100px;
                background-color: $blue;
                color: $white;
                padding: 12px 39px;
                border: none;
                border-radius: 5px;
                font-family: $primary-font-family;
                font-size: $font-size-p;
            }
        }
    }
    &__header{
        // position: fixed;
        top: 0px;
        margin: 67px auto 50px;
        width: 100%;
        text-align: right;

        &__container{
            max-width: 75%;
            margin: auto;
            @include flex;
            justify-content: space-between;
            align-content: center;
        }

        &__logo_container{
            width: calc(100% - 57px);
        }

        &__logo_container_login{
            width: 100%;
        }

        &__logo_container_login, &__logo_container{
            text-align: center;
        }

        button{
            // margin: 30px 30px 0px 0px;
            background-color: transparent;
            color: $greyOP;
            padding: 0;
            border: none;
            font-family: $primary-font-family;
            font-size: $font-size-p; 
        }
    }
}