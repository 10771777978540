// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/utilities/text";
// @import "~bootstrap/scss/utilities/display";
// Colours
$black: #000;
$white: #FFF;
$greyOP:#818A91;
$ltGrey: #dddddd;
$dkGrey: #373A3C;


$blue: #1C7CD5;

// column sizes

$col-3: calc(100% / 3);
$col-6: calc(100% / 1.5);
$col-1-5: calc(100% / 6);


//Menu
$menuHeight: 40px;

//Viewport
$mobileViewableArea: calc(100vh - #{$menuHeight});
$viewableArea: calc(100vh - 51px);

// Colour Roles
$primary-color: $dkGrey;
$secondary-color: $white;

// Screen Sizes
$screen-xl: 1200px; // ~1200px
$screen-lg: 992px; // ~992px
$screen-md: 768px; // ~768px
$screen-sm: 576px; // ~576px
$screen-xs: 0px; // 0

// Screen Sizes Max
$screen-xl-max: $screen-xl - 1;
$screen-lg-max: $screen-lg - 1;
$screen-md-max: $screen-md - 1;
$screen-sm-max: $screen-sm - 1;

// Sizes
$font-size-base: 1.6rem;

$font-size-base: 10px;
$font-size-h1:calc(30px + (50 - 30) * ((100vw - 300px) / (1100 - 300)));
$font-size-h2: 32px;
$font-size-p: 16px;



// Letter spacings



// Fonts
@import url("https://use.typekit.net/ohk8ifa.css");

$font-helvetica:'Helvetica Neue, Helvetica, Arial, sans-serif';
$font-source-sans-3:'source-sans-3';

$weight-extra-light: 100;
$weight-light: 300;
$weight-normal: 400;
$weight-bold: 700;
$weight-extra-bold: 900;

$primary-font-family: $font-source-sans-3;
$primary-font-size: 1.6rem;
$primary-weight: $weight-normal;
$primary-letter-spacing: 0rem;
$primary-word-spacing:  0rem;